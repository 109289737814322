body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-width: 320px;
}

@font-face {
    font-family: 'Metric';
    font-style: normal;
    font-weight: 300;
    src: local(Metric-Light), url('./fonts/Metric/MetricWeb-Thin.woff2');
}
@font-face {
    font-family: 'Metric';
    font-style: normal;
    font-weight: 400;
    src: local(Metric-Regular), url('./fonts/Metric/MetricWeb-Regular.woff2');
}
@font-face {
    font-family: 'Metric';
    font-style: normal;
    font-weight: 500;
    src: local(Metric-Medium), url('./fonts/Metric/MetricWeb-Medium.woff2');
}
@font-face {
    font-family: 'Metric';
    font-style: normal;
    font-weight: 600;
    src: local(Metric-Semibold), url('./fonts/Metric/MetricWeb-Semibold.woff2');
}
@font-face {
    font-family: 'Metric';
    font-style: normal;
    font-weight: 700;
    src: local(Metric-Bold), url('./fonts/Metric/MetricWeb-Bold.woff2');
}

/* remove bottom margin between paragraph followed by list for Contentful stopgap */
.stopgap-paragraph:has(+ .stopgap-ul) {
    margin: 0;
}

.stopgap-paragraph:has(+ .stopgap-ol) {
    margin: 0;
}

/* remove bottom margin between paragraph followed by list for Contentful dev, int & prod */
.epolms-paragraph:has(+ .epolms-ul) {
    margin: 0;
}

.epolms-paragraph:has(+ .epolms-ol) {
    margin: 0;
}

/* handle nested ordered list bullet styling for Contentful stopgap */
ol.stopgap-ol > li {
    list-style-type: decimal;
}

ol.stopgap-ol > li > ol > li {
    list-style-type: upper-alpha;
}

ol.stopgap-ol > li > ol > li > ol > li {
    list-style-type: lower-roman;
}

ol.stopgap-ol > li > ol > li > ol > li > ol > li {
    list-style-type: lower-alpha;
}

/* handle nested ordered list bullet styling for Contentful dev, int & prod */
ol.epolms-ol > li {
    list-style-type: decimal;
}

ol.epolms-ol > li > ol > li {
    list-style-type: upper-alpha;
}

ol.epolms-ol > li > ol > li > ol > li {
    list-style-type: lower-roman;
}

ol.epolms-ol > li > ol > li > ol > li > ol > li {
    list-style-type: lower-alpha;
}
